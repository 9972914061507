import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"

const Politica = ({ data }) => {

  return (
    <StyledPolitica>
      <p className=" title">
        <span className="smallUnderline"></span>
        POLÍTICA DE PRIVACIDADE
      </p>

      <p className="">Upcare Medical Center, com sede na Estrada Nacional 109, n.o 167
        Distrito: Aveiro Concelho: Aveiro Freguesia: Aradas 3810 140 Aveiro, com
        capital social de 10.000,00 Euros(dez mil euros), registado na
        Conservatória do Registo Comercial de Aveiro, com o número
        513594949. Política de Privacidade Upcare Medical Center é responsável
        pelo Upcare, adiante designado por site upcare.pt, e compromete-se a
        garantir a privacidade dos dados pessoais recolhidos e/ou transmitidos
        online. A segurança e a privacidade dos dados dos utilizadores do site
        upcare.pt são aspectos que assumem para a empresa uma importância
        crucial.</p>
      <p className="">Por favor, leia o texto que se segue, para compreender e tomar
        conhecimento da forma como as informações que disponibilizar
        serão tratadas pela Upcare Medical Center. A Upcare Medical
        Center reserva-se o direito de alterar a política de privacidade, pelo
        que se aconselha a leitura regular deste documento. Estes textos
        reproduzem fielmente a política de privacidade seguida pelo site
        upcare.pt.</p>
      <p className="bold">No presente documento, poderá encontrar a resposta às
        seguintes questões:</p>
      <p className=""><span className="bold">1 |</span> Qual é a informação que é recolhida sobre os utilizadores?</p>
      <p className=""><span className="bold">2 |</span> Qual é a entidade responsável pela recolha da informação?</p>
      <p className=""><span className="bold">3 |</span> O que são cookies e como é que a Up Care Medical Center os utiliza?</p>
      <p className=""><span className="bold">4 |</span> Como é que a informação recolhida é utilizada?</p>
      <p className=""><span className="bold">5 |</span> Que procedimentos de segurança tem o site wwww.upcare.pt, que garantam ao utilizador a protecção dos seus dados contra o acesso, a alteração ou a destruição não autorizada?</p>
      <p className=""><span className="bold">6 |</span> De que forma pode o utilizador corrigir ou eliminar as informações que facultou previamente através do website www.upcare.pt?</p>
      <p className=""><span className="bold">7 |</span> Outras questões de que deverá ter conhecimento sobre a política de privacidade da Up Care Medical Center.</p>
      <p className="">Se tiver qualquer dúvida sobre alguns dos pontos aqui
        apresentados, agradecemos que nos comunique por email
        para geral@upcare.pt ou por correio para Upcare Medical
        Center, Estrada Nacional 109, n.o 167 Distrito: Aveiro
        Concelho: Aveiro Freguesia: Aradas 3810 140 Aveiro.</p>

      <p className=""><span className="bold">1 | Qual é a informação que é recolhida sobre os utilizadores?</span><br />
        Para que se possa tornar um subscritor da nossa newsletter
        através do website upcare.pt é necessário proceder ao
        preenchimento do formulário que se encontra disponível online. A
        recolha dos dados pessoais é efectuada no momento do
        preenchimento do formulário. A informação recolhida é a
        necessária para assegurar a identidade do utilizador. No acto de
        registo, a informação que o utilizador disponibiliza tem por objectivo
        a prestação de um serviço mais adequado às suas características
        e necessidades e ao envio de informações relacionadas com os
        serviços prestados pela Upcare Medical Center, tais como: dicas de
        saúde oral, eventos ou informações gerais. O leitor poderá
        actualizar as informações por si fornecidas sempre que quiser
        bastando que para esse efeito, envie o pedido para
        geral@upcare.pt. As operações de fornecimento de dados pessoais
        estão devidamente protegidas. Toda a informação é encriptada e
        gerida com as mais avançadas ferramentas de segurança. </p>
      <p className=""><span className="bold">2 | Qual é a entidade responsável pela recolha da informação?</span><br />
        A Upcare Medical Center é a única responsável pela recolha de
        dados pessoais efectuada no site upcare.pt. Sempre que o
        utilizador termine a utilização deste serviço, a Upcare Medical
        Center não assume qualquer responsabilidade pela informação
        recolhida fora das respectivas páginas electrónicas. </p>
      <p className=""><span className="bold">3 | O que são cookies e como é que Up Care Medical Center os utiliza?</span><br />
        Para poder prestar um serviço mais personalizado e ao agrado dos
        utilizadores, A Upcare Medical Center utiliza cookies para recolher
        e guardar informação. Um cookie é um pequeno ficheiro
        informativo que é enviado do servidor do site www.upcare.pt para o
        browser do utilizador e armazenado no respectivo computador.
        Estes cookies serão apenas utilizados pela Upcare Medical Center
        e a sua utilização limita-se às seguintes finalidades: – permitir a
        Upcare Medical Center saber quem é o utilizador e, dessa forma,
        prestar um serviço melhor, mais seguro e personalizado; – estimar
        os níveis de utilização dos serviços. A cada utilizador corresponde
        um cookie individual, garantindo desta forma a privacidade e a
        segurança dos dados. A apresentação dos dados estatísticos é
        feita de uma forma agregada e não individual, não permitindo a
        identificação do utilizador. Desta forma, é possível avaliar o
        desempenho do site upcare.pt numa perspectiva de actualização e
        melhoria constantes, para que se possa satisfazer os gostos e as
        necessidades do utilizador.</p>
      <p className=""><span className="bold">4 | Como é que a informação recolhida é utilizada?</span><br />
        A informação recolhida servirá apenas para os seguintes
        propósitos: – elaboração de estatísticas que permitam perceber
        como a Upcare Medical Center poderá melhorar o nível de serviços
        que presta e, dessa forma, corresponder mais eficazmente às
        expectativas dos utilizadores; – aumentar o nível de personalização
        dos serviços para que se adeqúem, na maior medida possível, aos gostos e preferências dos utilizadores.
        Os dados pessoais fornecidos pelo utilizador serão utilizados
        exclusivamente para fins ligados à execução do respectivo
        contrato, bem como, se o utilizador assim o autorizar, para
        actividades de informação, marketing e telemarketing da Upcare
        Medical Center. As comunicações de dados serão feitas mediante
        prévio consentimento dos titulares dos dados. Upcare Medical
        Center não procederá à venda, aluguer, partilha ou oferta dos
        dados fornecidos pelos seus leitores, excepto nas situações
        previstas na presente política de privacidade.
      </p>
      <p className=""><span className="bold">5 | Que procedimentos de segurança tem o site Up Care Medical Center que garanta
        aos utilizadores a protecção dos seus dados contra o acesso, a alteração ou a
        destruição não autorizada?</span><br />
        A Upcare Medical Center toma todas as precauções necessárias e
        legalmente exigidas para garantir a protecção da informação
        recolhida junto dos seus utilizadores através do site Upcare
        Medical Center. Estas precauções garantem a segurança online e
        offline dessa informação. Se tiver alguma questão a colocar-nos
        sobre a segurança e privacidade que a Upcare Medical Center lhe
        garante, entre em contacto para +351 234 426 640 / +351 968 788
        645 ou por correio para Upcare Medical Center, Estrada Nacional
        109, n.o 167 Distrito: Aveiro Concelho: Aveiro Freguesia: Aradas
        3810 140 Aveiro.</p>
      <p className=""><span className="bold">6 | De que forma pode o utilizador corrigir ou eliminar as informações que facultou previamente nas aplicações do Público?</span><br />
        No âmbito das condições de utilização do site upcare.pt e de todos
        os seus serviços, o utilizador compromete-se a fornecer e a manter
        actualizados e verdadeiros todos os seus dados pessoais. Por sua
        vez, a Upcare Medical Center, garante ao utilizador a possibilidade
        de corrigir e actualizar os seus dados, devendo o utilizador
        contactar a Upcare Medical Center por e-mail para
        geral@upcare.pt ou por correio, para a Estrada Nacional 109, n.o
        167 Distrito: Aveiro Concelho: Aveiro Freguesia: Aradas 3810 140
        Aveiro. Todos os utilizadores poderão, através do website <a href="https://www.upcare.pt"
          style={{ color: "#C2A257", display: "inline", textDecoration: "underline" }}>www.upcare.pt</a> subscrever newsletters. Em todas as newsletters
        enviadas aos subscritos do website Up Care Medical Center têm a
        opção de cancelamento da subscrição.</p>
      <p className=""><span className="bold">7 |  Outras questões de que deverá ter conhecimento sobre a política de privacidade do
        website Up Care Medical Center:</span></p>
      <p className=""><span className="">– Alteração de Condições de Utilização do site da Upcare</span><br />
        Sempre que houver uma alteração das condições de utilização do
        site upcare.pt, designadamente nas Condições de Serviço ou na
        Política de Privacidade, a Upcare Medical Center enviará um email
        aos seus utilizadores.
      </p>
      <p className=""><span className="">– Autorização para Utilizações Diversas</span><br />
        Se a Upcare Medical Center pretender utilizar os dados dos seus
        utilizadores para finalidades distintas daquelas para as quais
        recebeu autorização inicial dos utilizadores, solicitará uma nova
        autorização dos utilizadores para essa utilização específica.</p>
    </StyledPolitica>
  )
}

export default Politica

const StyledPolitica = styled.div`
  position: relative;
  padding: 0 20% 5em;
  .title{
    color: #231f20;
    font-size: calc(19px + 21 * (100vw - 300px) / 1620);
    line-height: 33px;
    position: relative;
    margin: 2.5em 0;
    letter-spacing: 6px;
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #C2A257;
      position: absolute;
      top: 1.6em;
      left: 0;
    }
  }
  p{
    font-size: calc(12px + 12 * (100vw - 300px) / 1620);
    line-height: calc(22px + 11 * (100vw - 300px) / 1620);
    margin: 2em 0;
    color: #707070;
  }
  @media only screen and (max-width: 1200px){
    padding: 0 18% 5em;
  }
  @media only screen and (max-width: 696px){
    .title{
      .smallUnderline{
        top: 3em;
      }
    }
  }
  @media only screen and (max-width: 500px){
    .title{
      .smallUnderline{
        top: 3.5em;
      }
    }
  } 
`